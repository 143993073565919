function loadDataTableListPage(table, order, hidden = false, filter = false,excel=false, showOnlyActive = false, activeLabel = 'Only Active', inactiveLabel = 'All') {
    if (!$().DataTable) {
        console.warn('Warning - datatables.min.js is not loaded.');
        return;
    }

    var dataTableDefaults = {
        autoWidth: false,
        responsive: true,
        orderCellsTop: true,
        fixedHeader: true,
        pageLength: 10,
        dom: '<"datatable-header"fl><"datatable-scroll"t><"datatable-footer"ip>',
        //dom: '<"datatable-header"fl<"active-checkbox">><"datatable-scroll"t><"datatable-footer"ip>',
        language: {
            url: "/build/json/datatables/hungarian.json",
            searchPlaceholder: "Keresés mindenben"
        },
        drawCallback: function () {
            if (showOnlyActive) {
                var activeCheckbox = $('.active-checkbox input[type="checkbox"]');
                activeCheckbox.prop('checked', false);
                activeCheckbox.off().on('change', function () {
                    var dataTable = $('#' + table).DataTable();
                    dataTable.column(0).search(activeCheckbox.prop('checked') ? '1' : '', true, false).draw();
                });
            }
        }
    };
    if (excel !== false) {

        var dataTableDefaults = $.extend(true, {}, dataTableDefaults, {
            dom: 'Bfrtip',
            buttons: ['excel']
        });
    }
    if (order !== false) {
        dataTableDefaults.order = [[order[0], order[1]]];
    }

    if (hidden) {
        dataTableDefaults.columnDefs = [
            {
                targets: 0,
                visible: false,
            },
        ];
    }

    if (filter) {
        $('#' + table + ' thead tr')
            .clone(true)
            .addClass('filters')
            .appendTo('#' + table + ' thead');

        dataTableDefaults.initComplete = function () {
            var api = this.api();

            api.columns().eq(0).each(function (colIdx) {
                var cell = $('.filters th').eq($(api.column(colIdx).header()).index());
                var title = $(cell).text();

                if (title !== 'Funkció' && title !== 'action') {
                    $(cell).html('<input class="form-control" type="text" placeholder="' + title + '" />');
                } else {
                    $(cell).html('');
                }

                $('input', cell)
                    .off('keyup change')
                    .on('keyup change', function (e) {
                        e.stopPropagation();

                        var regexr = '({search})';
                        var cursorPosition = this.selectionStart;

                        api.column(colIdx)
                            .search(this.value !== '' ? regexr.replace('{search}', '(((' + this.value + ')))') : '',
                                this.value !== '', this.value === '')
                            .draw();

                        $(this).focus()[0].setSelectionRange(cursorPosition, cursorPosition);
                    });
            });
        };
    }

    if (showOnlyActive) {
        var showActiveText = 'Show active only';
        var showAllText = 'Show all';
        var activeCheckboxMarkup = '<div class="active-checkbox"><input type="checkbox" id="active-checkbox-toggle" data-active-text="' + showActiveText + '" data-all-text="' + showAllText + '"><label for="active-checkbox-toggle">' + showAllText + '</label></div>';
        $('.datatable-scroll').parent().before(activeCheckboxMarkup);

        $('#' + table).addClass('has-active-checkbox');
    
        $('#active-checkbox-toggle').on('change', function () {
            var toggleSwitch = $(this);
            var toggleText = toggleSwitch.is(':checked') ? toggleSwitch.attr('data-active-text') : toggleSwitch.attr('data-all-text');
            toggleSwitch.next('label').text(toggleText);
    
            if (toggleSwitch.is(':checked')) {
                dataTable.columns(1).search('1').draw();
            } else {
                dataTable.columns(1).search('').draw();
            }
        });
    }

    var dataTable = $('#' + table).DataTable(dataTableDefaults);
}
function deleteListRow(table, id, path, parentTR) {
    var table = $("#" + table).DataTable();

    var params = {
        _token: $('meta[name="csrf-token"]').attr("content"),
        id: id,
    };

    ezroJs.ajaxLoad(
        "/ezrosys/ajax/" + path + "/destroy/" + id,
        params,
        "DELETE",
        true,
        false
    ).then((data) => {
        if (data.success == "true") {

            ezroJs.notification('success', data.message, 2500, true, 'topRight');

            table.row(parentTR).remove().draw();
            return new Promise((resolve, reject) => {

                resolve('success');
            }

            );
            return 'success';
        } else {
            return new Promise((reject) => {

                reject('error');
                ezroJs.notification('error', data.message, 2500, true, 'topRight');
            }

            );

        }
    });
}

function focusOnFirst(name = false, timeout = false) {
    const focusElement = () => {
        if (name) {
            $(name).focus();
        } else {
            $('form :input:visible:first').focus();
        }
    };

    if (timeout) {
        setTimeout(focusElement, timeout);
    } else {
        focusElement();
    }
}

function ajaxLoad(url, params, postType = 'POST', showError = false, forward = false, spinner = false) {
    const $spinner = $(".lds-ripple");
    const options = {
        url,
        type: postType,
        data: params,
        beforeSend: function () {
            if (spinner) {
                $spinner.show();
            }
        }
    };

    return new Promise((resolve, reject) => {
        $.ajax(options).done(function (response) {
            if (forward == false) {
                resolve(response);
                $spinner.hide();
            }
        }).fail(function (jqXHR, textStatus, error) {
            if (showError) {
                ezroJs.notification('error', 'Ajax hiba :(', 2500, true, 'topRight');
                ezroJs.notification('error', error, 2500, true, 'topRight');
                ezroJs.notification('error', textStatus, 2500, true, 'topRight');
            }
            reject(error);
        }).always(function () {
            $spinner.hide();
        });
    });
}

function findValByText(el, text) {
    return $("#" + el)
        .find("option:contains('" + text + "')")
        .val();
}

function isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
}
function roundPrecision(num, precision) {
    return +(Math.round(num + "e+" + precision) + "e-" + precision);
}
function validateNumber(number) {
    var re = /^\d.+$/;
    return re.test(number);
}
function applyDateFilter(table,dateFieldIndex,dateFormat,dateFromField,dateToField, callback) {
    var table = $('#'+table).DataTable();
    $.fn.dataTable.ext.search.push(
        function(settings, data, dataIndex) {
            var dateValue = moment(data[dateFieldIndex], dateFormat); // Assuming date format is 'YYYY-MM-DD'

            var min = $('#'+dateFromField).val();
            var max = $('#'+dateToField).val();

            if (min === "" && max === "") {
                // If both date inputs are empty, show all rows
                return true;
            }

            if (min !== "" && max === "") {
                // If only 'date_from' is present, filter for dates bigger than or equal to it
                return dateValue.isSameOrAfter(moment(min, dateFormat));
            }

            if (min === "" && max !== "") {
                // If only 'date_to' is present, filter for dates smaller than or equal to it
                return dateValue.isSameOrBefore(moment(max, dateFormat));
            }

            if (min !== "" && max !== "") {
                // If both 'date_from' and 'date_to' are present, filter for dates between them
                return dateValue.isBetween(moment(min, dateFormat), moment(max, dateFormat), null,
                '[]'); // '[]' includes the bounds
            }

            return false; // Default: Do not include this row
        }
    );
       
    table.draw();
    if (typeof callback === 'function') {
        callback();
    }
    
}
function ezro_number_format(number_input, decimals = 0, dec_point = '.', thousands_sep = ',') {
    let number = Number.parseFloat(number_input);
    if (Number.isNaN(number)) {
        number = 0;
    }
    let finite_decimals = Math.abs(Number.parseInt(decimals));
    let number_output = number.toFixed(finite_decimals).toString().split('.');
    number_output[0] = number_output[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousands_sep);
    return number_output.join(dec_point);
}
function notification(type, message, timeout, progressBar, layout) {

    // notification('success','message',2500,true,'bottomLeft');
    if (!layout) layout = 'top-end';


    Swal.fire({
        position: "top-end",
        icon: type,
        title: message,
        showConfirmButton: false,
        timer: 1500
      });

    
}
function taxlookup(id) {
    const modalMode = id.match(/modal/);
    const divPrefix = modalMode ? 'modal_' : '';

    const parentId = id.split('taxlookup_')[1];
    const taxVal = $('#' + parentId).val().substring(0, 8);

    if (!taxVal) {
        ezroJs.notification('error', 'Adószám lekérdezéshez legalább a 8 jegyű törzsszám megadása szükséges', 2500, true, 'topRight');
        return;
    }

    const params = {
        _token: $('meta[name="csrf-token"]').attr('content'),
        taxNo: taxVal
    };
    ezroJs.ajaxLoad('/ezrosys/ajax/nav/queryTaxPayer', params, "POST", true, false)
        .then((data) => {
            console.log(data.data);
            if (data.status === 'false') {
                ezroJs.notification('error', data.message, 2500, true, 'topRight');
                return;
            }

            ezroJs.notification('success', data.message, 2500, true, 'topRight');

            const fullTax = data.data.taxNumberDetail.taxpayerId + "-" + data.data.taxNumberDetail.vatCode + "-" + data.data.taxNumberDetail.countyCode;
            const taxpayerAddress = data.data.taxpayerAddressList.taxpayerAddressItem[0]?.taxpayerAddress || data.data.taxpayerAddressList.taxpayerAddressItem.taxpayerAddress;
            const zip = taxpayerAddress.postalCode;
            $('#tax_no').val(fullTax);
            $('#' + divPrefix + 'name').val(data.data.taxpayerName).trigger('change');
            $('#' + divPrefix + 'country_code').val(taxpayerAddress.countryCode).trigger('change');
            $('#' + divPrefix + 'zip').val(taxpayerAddress.postalCode);
            $('#' + divPrefix + 'city').val(taxpayerAddress.city);
            $('#' + divPrefix + 'street').val(taxpayerAddress.streetName);
            $('#' + divPrefix + 'number').val(taxpayerAddress.number);
            $('#' + divPrefix + 'building').val(taxpayerAddress.building);
            $('#' + divPrefix + 'floor').val(taxpayerAddress.floor);
            $('#' + divPrefix + 'door').val(taxpayerAddress.door);

            const select = $('#' + divPrefix + 'zip');
            if (!select.find(`option[value='${zip}']`).length) {
                select.append(new Option(zip, zip, true, true)).trigger('change');
            }
        });

}
function initDropZone(el, params) {

    if (typeof Dropzone == 'undefined') {
        console.warn('Warning - dropzone.min.js is not loaded.');
        return;
    }
    let dropzoneControl = $(el).dropzone;
    console.log(dropzoneControl);
    $(el).dropzone(params);
}

function loadAjaxTable(query,url,columns,order=false,stateSave=false) {
    if ($.fn.DataTable.isDataTable('#main_table')) {
        $("#main_table").dataTable().fnDestroy();
    }
    var table = $('#main_table').DataTable({
        processing: true,
        serverSide: true,
        order: order,
        stateSave: stateSave,
        "ajax": {
            "url": url,
            "type": "POST",
            "data": {
                _token: $('meta[name="csrf-token"]').attr('content'),
                query: query
            },
            language: {
                url: "/build/json/datatables/hungarian.json",
                searchPlaceholder: "Keresés mindenben"

            },

            pageLength: 10,

        },

        columns: 

            columns
        ,
        "rowCallback": function(row, data) {
            // Assigning an ID to each row
            $(row).attr("id", data
                .id); // Assuming you have an 'id' field in the data from the database
        },
        columnDefs: [{
           
        }, ],
        initComplete: function() {
            this.api().columns().every(function() {
                var column = this;
                var input = document.createElement('input');
                input.setAttribute('class', 'form-control form-control-sm');


                $(input).appendTo($(column.header()))
                    .on('keyup change', function() {
                        column.search($(this).val(), false, false, true).draw();
                    });
            });
        },


    });
    $('#main_table thead tr')
        .clone(true)
        .addClass('filters')
        .appendTo('#main_table thead');
}
 function zipSearch(el){
    $('#'+el).select2({

        tags: false,
        minimumInputLength: 3,
        placeholder: '---keresés---',
        ajax: {
            url: '/ezrosys/ajax/getZips',
            data: function (params) {
                var query = {
                    search: params.term,
                }
                return query;
            },

            processResults: function (data) {
                let datajson = JSON.parse(data);
                var myResults = [];
                localStorage.setItem("zipResults",  JSON.stringify(datajson));
                $.each(datajson.results, function (index, item) {
                    myResults.push({
                        'id': index,
                        'text': index
                    });

                });
                return {
                    results: myResults
                };

            },

        }
        }).on('select2:select', function (e) {
    let selectedZip = e.params.data.id;
    let storedZips = JSON.parse(localStorage.getItem('zipResults'));
    $('#city').val(storedZips.results[selectedZip]);
     });
 }
export const ezroJs = {
    loadDataTableListPage,
    focusOnFirst,
    deleteListRow,
    ajaxLoad,
    findValByText,
    isNumeric,
    roundPrecision,
    validateNumber,
    applyDateFilter,
    ezro_number_format,
    taxlookup,
    notification,  
    initDropZone,
    loadAjaxTable,
    zipSearch
    







}
window.ezroJs = ezroJs;

